@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: #fafafa;
  font-weight: 400;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.width-90 {
  width: 90%;
}

a {
  color: #cfa35a;
  text-decoration: none;
}

a:hover {
  color: #a68248;
}

.mat-card-header {
  width: 100%;
}

.mat-card-header-text {
  width: 100%;
}

.mat-card-subtitle {
  width: 100%;
}

.mat-spacer {
  flex: 1 1 auto;
}

.mat-tooltip {
  background-color: darkgrey !important;
}

.text-justify {
  text-align: justify;
}

.row {
  margin-bottom: 1rem;
}

button:focus {
  outline: none;
  border: 0;
}

mat-card {
  margin: 1rem;
}

footer {
  padding: 0 1rem;
  font-size: smaller;
}

footer > a {
  color: rgba(0, 0, 0, 0.87);
}

mat-form-field {
  width: 100%;
  margin-right: 1rem;
}

.drop-zone {
  border: 2px dotted darkgrey;
  border-radius: 16px;
  height: 60px;
  margin: auto;
}

.drop-content {
  align-items: center;
  color: darkgrey;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.table-fixed {
  width: 100%;
}

.table-fixed tbody {
  height: 200px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.mat-list-base .mat-list-item-disabled {
  background-color: unset;
}
